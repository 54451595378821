.cm {
  width: 100%;
  margin-block: var(--margin-block);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 180px;
}

.setppp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--black);
  color: var(--white);
  padding: 2rem;
  text-align: center;
  gap: var(--gap);
  border-radius: var(--border-radius-10);
  border: 10px solid var(--red);
  margin-bottom: var(--margin-block);
  width: min(100%, 700px);
  align-self: flex-start;
}

.setppp h1 {
  font-family: var(--cursive-font);
}

.setform {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: var(--standard-font);
}

.setform > * {
  width: min(100%, 700px);
  accent-color: var(--red);
}

.setform input,
.setform textarea {
  border-radius: var(--border-radius-10);
  padding: 0.5rem 1.25rem;
  border: 0;
  outline: 0;
  border-bottom: solid 2px rgb(227, 227, 227);
  font-size: clamp(var(--clamp-xs-text));
  background-color: rgb(242, 242, 242);
  border: solid 2px rgba(216, 32, 32, 0.25);
  color: rgb(129, 129, 129);
  font-weight: bold;
}

.setform textarea {
  resize: none;
  height: 275px;
}

.setform label {
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
}

.form-btn {
  background-color: var(--red);
  color: var(--white);
  border: 0;
  outline: 0;
  cursor: pointer;
  width: min(100%, 250px);
  border-radius: var(--border-radius-20);
  padding: 0.75rem 1.25rem;
  font-weight: bold;
  font-size: clamp(var(--clamp-small-text));
  font-family: var(--standard-font);
  border: solid 5px var(--red);
  box-shadow: 0 0 16px rgb(90, 90, 90);
  transition: var(--transition-short);
}

.form-btn:hover {
  background-color: var(--white);
  color: var(--red);
  border: solid 5px var(--red);
}

.cont-add {
  background-color: var(--darkgrey);
  color: var(--white);
  border-radius: var(--border-radius-10);
  padding: 2rem;
  width: min(100%, 700px);
  line-height: 1.3;
}

.cont-add span {
  color: var(--yellow);
  font-weight: bold;
}

.djb-map {
  width: min(100%, 700px);
  height: 400px;
  align-self: flex-start;
  margin-top: var(--margin-block-big);
  margin-bottom: var(--gap);
  border-radius: var(--border-radius-20);
  filter: drop-shadow(var(--drop-shadow-lighter));
}

.map-h4 {
  align-self: flex-start;
  width: min(100%, 700px);
  margin-top: var(--gap);
  margin-bottom: var(--margin-block-big);
  color: rgb(129, 129, 129);
  font-size: clamp(var(--clamp-xs-text));
}

.map-link {
  align-self: flex-start;
  width: min(100%, 700px);
  text-decoration: none;
  background-color: var(--red);
  color: var(--white);
  border-radius: var(--border-radius-20);
  transition: var(--transition-short);
  padding: 0.75rem 2rem;
  text-align: center;
  font-size: clamp(var(--clamp-xs-text));
  text-transform: uppercase;
  font-weight: bold;
  border: 5px solid var(--red);
  box-shadow: 0 0 16px rgb(90, 90, 90);
}

.map-link:hover {
  background-color: var(--white);
  color: var(--red);
}

.is-error {
  background-color: var(--red);
  color: var(--white);
  padding: 1rem 2rem;
  border-radius: var(--border-radius-20);
  margin-top: var(--margin-block);
}

.is-error a {
  text-decoration: none;
  color: var(--black);
  font-weight: bold;
}

.is-correct {
  background-color: #32a834;
  color: var(--white);
  padding: 1rem 2rem;
  border-radius: var(--border-radius-20);
  margin-top: var(--margin-block);
}

.mb-link {
  color: rgb(129, 129, 129);
  border-block: 3px solid rgb(242, 242, 242);
  border-block: 3px solid rgba(216, 32, 32, 0.3);
  padding-block: 20px;
}

.mb-link a {
  text-decoration: none;
  color: var(--red);
  font-weight: bold;
  transition: var(--transition-short);
}

.mb-link a:hover {
  opacity: 0.65;
}

@media (max-width: 750px) {
  .cm {
    padding-inline: 5%;
  }
}
