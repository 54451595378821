.sitemap {
  width: 100%;
}

.sitemap-more {
  padding-block: var(--margin-block);
  padding-inline: 180px;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.sitemap-more a {
  text-decoration: none;
  color: var(--white);
  background-color: var(--black);
  padding: 0.75rem 2rem;
  border-radius: var(--border-radius-10);
  width: fit-content;
  transition: var(--transition-short);
}

.sitemap-more a:hover {
  background-color: var(--white);
  color: var(--darkgrey);
}

.site-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-block: rgb(245, 168, 168) solid 2px;
  padding-block: 0.5rem;
}

.sitemap-more > * {
  width: min(100%, 700px);
}

.last-content {
  padding-block: 1rem;
  padding-inline: 180px;
  background-color: var(--red);
  font-size: clamp(var(--clamp-site-text));
  padding-bottom: var(--margin-block);
  color: var(--white);
}

@media (max-width: 750px) {
  .sitemap-more,
  .last-content {
    padding-inline: 5%;
  }
}
