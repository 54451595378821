.financije {
  width: 100%;
}

.fin-more {
  padding-inline: 180px;
  padding-block: var(--margin-block);
}

.fin-more > * {
  width: min(100%, 700px);
}

.fin-more p {
  margin-block: 50px;
  border-block: solid 3px rgba(216, 32, 32, 0.5);
  padding-block: 0.5rem;
  width: min(100%, 700px);
}

.fin-more p a {
  color: var(--red);
  font-weight: bold;
  text-decoration: none;
}

.gfi {
  color: var(--red);
  text-decoration: none;
  font-size: clamp(var(--clamp-xs-text));
  font-weight: bold;
  transition: var(--transition-short);
  width: min(100%, 700px);
  max-width: 700px;
}

.gfi:hover {
  color: var(--black);
}

.yellowish {
  background-color: var(--yellow);
  padding: 1.5rem;
  border-radius: var(--border-radius-10);
  filter: drop-shadow(var(--drop-shadow-middle));
}

.gfi.redish {
  cursor: default;
  color: rgb(97, 97, 97);
  color: var(--black);
}

.docs-fin {
  border: 5px solid var(--red);
  background-color: var(--red);
  border-radius: var(--border-radius-20);
  padding: 0.5rem 1.5rem;
  transition: var(--transition-short);
  text-align: center;
  width: fit-content;
}

.docs-fin a {
  text-decoration: none;
  color: var(--white);
  font-size: clamp(var(--clamp-xs-text));
  font-weight: bold;
}

.docs-fin:hover {
  background-color: var(--white);
}

.docs-fin:hover a {
  color: var(--red);
}

.galaxy {
  border-radius: var(--border-radius-10);
  filter: drop-shadow(var(--drop-shadow-lighter));
  overflow: hidden;
  height: 400px;
  position: relative;
  user-select: none;
}

.galaxy img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  animation: scaleUp 5s infinite ease-in-out alternate;
}

@keyframes scaleUp {
  to {
    transform: scale(1.5);
  }
}

.galaxy::before {
  position: absolute;
  content: "Korupcija je karcinom društva — budimo transparentni";
  top: 50%;
  left: 0%;
  width: 100%;
  height: 80px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
  padding-inline: 1rem;
  display: grid;
  place-content: center;
  text-align: center;
  z-index: 9;
  text-transform: uppercase;
}

.galaxy::after {
  position: absolute;
  content: "DOSTA JE BILO";
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: 50px;
  background-color: var(--red);
  color: var(--white);
  display: grid;
  place-content: center;
  padding: 0.25rem 1.25rem;
  font-weight: bold;
  box-shadow: 0 0 30px white;
  border-radius: 5px;
}

@media (max-width: 750px) {
  .fin-more {
    padding-inline: 5%;
  }
}
