.statute-more {
  width: 100%;
  padding-inline: 180px;
  padding-block: var(--margin-block);
  overflow: hidden;
}

.statute-more img {
  width: min(80%, 325px);
  user-select: none;
}

.pd-label-stat {
  margin-bottom: 60px;
  font-family: var(--cursive-font);
  font-size: clamp(var(--clamp-cursive-text));
}

.pd-label-name {
  padding: 0.75rem 2rem;
  background-color: var(--red);
  border-radius: var(--border-radius-10);
  color: var(--white);
  width: fit-content;
  box-shadow: 0 0 16px rgb(90, 90, 90);
  margin-block: 30px;
  margin-top: 40px;
  border: solid 4px white;
  font-size: clamp(var(--clamp-site-text));
}

.flexed {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-block: solid 4px rgba(216, 32, 32, 0.5);
  padding-block: 2.5rem;
}

.markbook {
  color: var(--red);
  font-weight: bold;
  transition: var(--transition-short);
  width: fit-content;
}

.markbook:hover {
  color: rgb(113, 113, 113);
}

.reg-def {
  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
  gap: 20px;
  width: min(200px, 100%);
  height: 300px;
  text-decoration: none;
  color: var(--darkgrey);
}

.reg-info {
  padding-inline: 1rem;
  border-inline: 5px solid rgba(216, 32, 32, 0.5);
}

.corrupt {
  display: flex;
  flex-direction: column;
}

.corrupt h1 {
  font-size: clamp(var(--clamp-more-text));
}

.corrupt > :nth-child(2) {
  border-top: solid 5px rgb(232, 232, 232);
  margin-top: -90px;
  color: var(--red);
}
.corrupt > :nth-child(4) {
  color: rgb(186, 185, 185);
  border-bottom: solid 5px rgb(232, 232, 232);
}

.corrupt h5 {
  margin-top: 40px;
}

.corrupt h3 {
  background-color: var(--red);
  padding: 0.25rem 1.5rem;
  color: var(--white);
  border-radius: var(--border-radius-10);
  box-shadow: 0 0 16px rgb(90, 90, 90);
  margin-top: 20px;
  text-align: center;
}

.corrupt a {
  color: var(--red);
  letter-spacing: 2px;
}

.corrupt p {
  font-family: var(--cursive-font);
  font-size: clamp(var(--clamp-big-text));
  margin-top: 60px;
  text-align: center;
  border-block: solid 5px var(--red);
  padding-block: 1rem;
}

.flag-spread {
  width: min(100%, 700px);
  filter: drop-shadow(0 0 4px silver);
}

@media (max-width: 750px) {
  .statute-more {
    padding-inline: var(--padding-inline-five-percent);
  }
}
