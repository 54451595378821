.dd {
  width: 100%;
  margin-bottom: var(--margin-block-big);
  background-color: var(--red);
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline: 180px;
  padding-block: 50px;
  gap: 80px;
  box-shadow: 0 0 5px;
  cursor: url("../files/cursorbig.png") 40 40, default;
  overflow-x: hidden;
}

.dd img {
  height: 90%;
  /*  width: 100%; */
  width: min(90%, 375px);
  object-fit: cover;
  user-select: none;
  border-radius: var(--border-radius-10);
  flex: 1;
  filter: drop-shadow(var(--drop-shadow-middle));
}

.dd-second {
  flex: 1;
  display: grid;
  place-content: center;
  place-items: center;
  gap: var(--gap);
}

.dd-second h3 {
  text-align: center;
}

.dd-second p {
  color: var(--black);
  background-color: var(--white);
  border-radius: var(--border-radius-10);
  padding: 0.25rem 2.5rem;
  font-weight: bold;
}

.dd-second span {
  text-align: center;
  color: var(--white);
}

.dd-second h5 {
  background-color: var(--black);
  color: var(--yellow);
  border-radius: var(--border-radius-10);
  text-align: center;
  padding: 0.25rem 1rem;
}

@media (max-width: 850px) {
  .dd {
    flex-direction: column;
    padding-inline: 5%;
  }
}
