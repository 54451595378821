.documents {
  width: 100%;
}

.djb-docs {
  padding-block: var(--margin-block);
  padding-inline: 180px;
  display: flex;
  flex-direction: column;
  gap: 180px;
}

.djb-docs-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.djb-docs-box h3 {
  padding: 0.5rem 1.5rem;
  background-color: var(--red);
  width: fit-content;
  border-radius: var(--border-radius-10);
  box-shadow: 0 0 20px rgb(120, 120, 120);
  color: var(--white);
}

.djb-docs-box > p {
  background-color: var(--darkgrey);
  /*   background-color: #32a834;
 */
  color: var(--white);
  padding: 1.5rem;
  border-radius: var(--border-radius-10);
  box-shadow: 0 0 20px rgb(162, 162, 162);
}

.djb-docs-box-inside {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
  border-bottom: solid 5px var(--red);
  padding-bottom: 20px;
  margin-top: 40px;
}

.logo-itself {
  display: grid;
  place-items: center;
  width: 120px;
  gap: 1rem;
  height: fit-content;
  overflow: hidden;
  text-decoration: none;
  color: var(--grey);
  margin-top: 40px;
  transition: var(--transition-short);
}

.logo-itself:hover {
  opacity: 0.68;
}

.logo-itself img {
  width: 90%;
  user-select: none;
}

.logo-itself p {
  text-align: center;
}

@media (max-width: 750px) {
  .djb-docs {
    padding-inline: 5%;
  }
}
