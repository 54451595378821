@font-face {
  font-family: FiraSans;
  src: url("./files/Fira_Sans/FiraSans-Regular.ttf") format("ttf");
}

html {
  scroll-behavior: smooth;
}

:root {
  /* Colours */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --red: #d82020;
  --white: white;
  --black: #111111;
  --grey: grey;
  --darkgrey: rgb(50, 50, 50);
  --lightgrey: rgb(221, 221, 221);
  --lightred: #e34f63;
  --yellow: #fbeb63;
  --gold: #e49216;
  --pink: #de1e91;
  --purple: #b839cf;
  --blue: #135ccb;
  --sea: #4a939a;
  --green: #4eada2;

  /* Font sizes */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */

  --clamp-big-text: 2.68rem, 10vw, 5rem;
  --clamp-more-text: 2.4rem, 9vw, 4.4rem;

  --clamp-middle-text: 2rem, 5vw, 3rem;
  --clamp-label-text: 2rem, 5vw, 2.5rem;
  --clamp-site-text: 1.5rem, 4vw, 2rem;

  --clamp-small-text: 1.1rem, 2vw, 1.25rem;
  --clamp-pd-text: 1.4rem, 3vw, 2rem;
  --clamp-head-text: 1.25rem, 3vw, 1.6rem;

  --clamp-xs-text: 0.95rem, 1.5vw, 1.1rem;

  --clamp-cursive-text: 5rem, 12.5vw, 9rem;

  /* Border radius */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --border-radius-20: 20px;
  --border-radius-10: 10px;

  /* Drop and box shadow */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --drop-shadow: 0 0 30px var(--black);
  --drop-shadow-lighter: 0 0 8px var(--darkgrey);
  --drop-shadow-white: 0 0 30px rgb(235, 235, 235);
  --drop-shadow-grey: 0 0 30px silver;
  --drop-shadow-middle: 0 0 18px rgb(160, 160, 160);

  /* Transition */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --transition-long: all 1s cubic-bezier(0.58, 0.16, 0.74, 1.25);
  --transition-middle: all 0.5s cubic-bezier(0.58, 0.16, 0.74, 1.25);
  --transition-short: all 0.25s cubic-bezier(0.58, 0.16, 0.74, 1.25);

  /* Distances, paddings and margins */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --padding-inline-five-percent: 5%;
  --padding-inline-ten-percent: 10%;
  --padding-inline-twenty-percent: 20%;
  --margin-block-big: 150px;
  --margin-block: 80px;
  --gap: 40px;

  /* Fonts */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
  --standard-font: Fira_Sans;
  --cursive-font: "Great Vibes", cursive;

  /* NAVIGATION and SPREAD MENU */
  /* ****************************************************** */
  /* ****************************************************** */
  /* ****************************************************** */
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: var(--standard-font);
  font-size: clamp(var(--clamp-small-text));
  width: 100%;
  /*   cursor: url("../src/files/cursor.png") 10 10, default;
 */
}

body > h1,
body > h2,
body > h3,
body > h4,
body > h5,
body > h6 {
  letter-spacing: -1px;
}

h1 {
  font-size: clamp(var(--clamp-big-text));
}

h3 {
  font-size: clamp(var(--clamp-middle-text));
}

h6 {
  font-size: clamp(var(--clamp-xs-text));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::selection {
  background-color: var(--red);
  color: var(--white);
}

::-webkit-scrollbar {
  border-left: 1px solid silver;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--red);
  background-color: #cccccc;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: var(--border-radius);
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--red);
}
