.volontiranje {
  width: 100%;
  /*   background-image: url("../files/balloons.png");
 */
  background-attachment: fixed;
  background-repeat: no-repeat;
  /*  background-color: rgb(255, 215, 215);
  background-position: right bottom;

  background-blend-mode: luminosity; */

  background-image: url("../files/jogger.jpeg");
  background-color: rgb(159, 108, 108);
  background-color: rgb(95, 88, 88);
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}
