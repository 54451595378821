.foot-all {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: default;
  overscroll-behavior-block: contain;
}

.footer {
  width: 100%;
}

.reset-pos {
  position: relative;
  z-index: 18;
  width: 100%;
}

.nav-track-foot {
  position: absolute;
  top: 0;
  left: 0;
  padding-inline: var(--padding-inline-five-percent);
  padding-block: 2rem;
  width: min(100%, 1200px);
  width: 100%;
  transition: var(--transition-long);
  transition-delay: 1.5s;
  overflow-x: hidden;
}

.nav-track-both-foot {
  display: flex;
  justify-content: space-between;
  border-block: 1px solid rgb(199, 199, 199);
  transition-delay: 1.5s;
}

.nav-track-1-foot,
.nav-track-2-foot {
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.nav-track-1-foot img {
  height: 50px;
  padding-block: 0.5rem;
  user-select: none;
}

.nav-track-1-foot p {
  font-weight: bold;
  padding-block: 0.5rem;
}

.nav-track-1-foot p span {
  font-weight: normal;
  font-size: clamp(var(--clamp-xs-text));
}

.nav-track-2-foot a {
  text-decoration: none;
  background-color: var(--red);
  color: var(--white);
  height: 100%;
  font-weight: bold;
  text-align: center;
  padding-inline: 0.25rem;
  display: grid;
  place-content: center;
  font-size: clamp(var(--clamp-xs-text));
  transition: var(--transition-short);
  margin-left: 8px; /* Button "Volontiraj" moved away from sign in footer */
}

.nav-track-2-foot a:hover {
  background-color: var(--white);
  color: var(--red);
}

.cross-foot {
  cursor: pointer;
  margin-right: 1.5rem;
  color: var(--red);
  transform: scale(1.3);
  animation: pulse 0.75s linear infinite alternate;
}

@keyframes pulse {
  to {
    transform: scale(0.9);
  }
}

.menu-links-foot {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 20px;
  margin-top: calc(var(--gap) * 1);
  border-bottom: 1px solid rgb(199, 199, 199);
  padding-bottom: calc(var(--gap) * 1);
}

.menu-links-foot a {
  text-decoration: none;
  color: var(--grey);
  width: fit-content;
  transition: var(--transition-short);
  font-size: clamp(var(--clamp-xs-text));
}

.menu-links-foot a:hover {
  color: var(--red);
}

.add-links-foot {
  display: flex;
  gap: calc(var(--gap) * 0.5);
  margin-top: calc(var(--gap) * 0.5);
  border-bottom: 1px solid rgb(199, 199, 199);
  padding-bottom: calc(var(--gap) * 0.5);
}

.add-links-foot a {
  text-decoration: none;
  color: var(--white);
  background-color: var(--red);
  padding: 0.75rem 2.5rem;
  border-radius: var(--border-radius-20);
  transition: var(--transition-short);
  display: grid;
  place-content: center;
  width: min(100%, 300px);
  box-shadow: 0 0 16px grey;
}

.add-links-foot a:nth-of-type(2) {
  background-color: var(--black);
}

.add-links-foot a:nth-of-type(3) {
  background-color: var(--lightgrey);
  color: var(--black);
}

.add-links-foot a:hover {
  background-color: var(--red);
  color: var(--white);
}

.cont-foot {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: var(--gap);
  margin-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgb(199, 199, 199);
}

.cont-foot a {
  text-decoration: none;
  color: var(--darkgrey);
  transition: var(--transition-short);
}

.cont-foot a:hover {
  color: var(--red);
}

.cont {
  display: grid;
  place-items: center;
  gap: 20px;
}

.cont h5 {
  text-align: center;
}

.footicon {
  transform: scale(1.25);
}

.label-foot {
  margin-block: 30px;
  border-bottom: 1px solid rgb(199, 199, 199);
  padding-bottom: 30px;
}

.web-creating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 80px;
  margin-bottom: 160px;
}

.mathscript {
  display: grid;
  place-items: center;
  gap: 12px;
  user-select: none;
}

.mathscript a {
  text-decoration: none;
  color: var(--red);
}

.web-creating p {
  font-size: clamp(var(--clamp-xs-text));
  width: min(100%, 575px);
  color: rgb(93, 93, 93);
  color: var(--white);
  text-align: left;
  background-color: var(--darkgrey);
  background-color: var(--black);
  padding: 0.75rem 2rem;
  border-radius: var(--border-radius-10);
  filter: drop-shadow(0 0 5px rgb(102, 102, 102));
  border: 12px solid var(--red);
  filter: drop-shadow(0 0 5px rgb(110, 110, 110));
  background-color: rgba(147, 12, 12, 0.95);
  background-image: url("../files/galaxy.jpeg");
  background-blend-mode: luminosity;
  background-size: ;
  background-position: center;
  font-weight: bold;
  text-shadow: 0 0 40px grey;
  user-select: none;
}

.mathscript img {
  height: 80px;
}

.rate-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(216, 32, 32, 0.95);
  z-index: 9999;
  display: grid;
  place-content: center;
  place-items: center;
  gap: var(--gap);
  color: var(--white);
  transform: scale(1);
  transform-origin: 250% 50%;
  transition: var(--transition-long);
}

.rate-box textarea {
  resize: none;
  border-radius: var(--border-radius-10);
  background-color: var(--black);
  outline: none;
  border: 0;
  color: var(--white);
  width: min(90vw, 550px);
  height: 80px;
  padding: 1rem;
  font-family: var(--standard-font);
  opacity: 0.9;
}

.rate-box textarea::placeholder {
  color: var(--yellow);
  opacity: 0.75;
}

.rate-box h3 {
  text-align: center;
  width: min(90vw, 550px);
}

.rate-box button {
  border: 0;
  outline: none;
  background-color: var(--white);
  border-radius: var(--border-radius-20);
  padding: 0.75rem 2rem;
  font-weight: bold;
  font-family: var(--standard-font);
  cursor: pointer;
}

.rate-box p {
  width: min(90vw, 550px);
  text-align: center;
  font-style: oblique;
  font-size: 80%;
}

.not-shown {
  transform: scale(0);
  height: 0;
  opacity: 0;
  font-size: 0%;
}

@media (max-width: 1000px) {
  .menu-links-foot {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 725px) {
  .cross-foot {
    margin-right: 0rem;
  }

  .nav-track-1-foot,
  .nav-track-2-foot {
    gap: calc(var(--gap) * 0.25);
  }

  .add-links-foot {
    flex-direction: column;
    gap: calc(var(--gap) * 0.5);
  }

  .cont {
    display: flex;
    place-items: start;
  }
}

@media (max-width: 550px) {
  .web-creating {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 120px;
    gap: 40px;
  }

  .mathscript {
    grid-template-columns: 1fr 1fr;
  }

  .web-creating p {
    text-align: left;
  }
}

@media (max-width: 300px) {
  .menu-links-foot {
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  }
}
