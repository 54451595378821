.lawsuit {
  width: 100%;
}

.law-steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-block: var(--margin-block);
  padding-inline: 180px;
}

.law-steps h4 {
  width: min(100%, 700px);
  color: var(--blak);
}

.docs-fin.smaller {
  width: min(100%, 700px);
}

.link-ls {
  text-decoration: none;
  color: var(--white);
  font-weight: bold;
  transition: var(--transition-short);
}

.link-ls:hover {
  color: var(--red);
}

.laws-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
}

.laws-boxes > * {
  width: min(100%, 425px);
  aspect-ratio: 1/1;
  border-radius: 50%;
  filter: drop-shadow(0 0 16px);
  overflow: hidden;
}

.common {
  background-size: cover;
  background-position: center;
  background-image: url("../files/zszg.jpeg");
  position: relative;
}

.constitution {
  background-size: cover;
  background-position: center;
  background-image: url("../files/usud.jpeg");
  position: relative;
}

.citizen {
  background-size: cover;
  background-position: center;
  background-image: url("../files/ogs.webp");
  position: relative;
}

.objection {
  background-size: cover;
  background-position: center;
  background-image: url("../files/vsrh.jpeg");
  position: relative;
}

.common h3,
.constitution h3,
.citizen h3,
.objection h3 {
  position: absolute;
  font-size: clamp(var(--clamp-site-text));
  background-color: var(--red);
  color: var(--white);
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 1000px;
  height: 80px;
  display: grid;
  place-content: center;
  transition: var(--transition-short);
}

.common h3:hover,
.constitution h3:hover,
.citizen h3:hover,
.objection h3:hover {
  background-color: var(--black);
  outline: 3px solid var(--white);
}

.laws-corr {
  width: min(100%, 700px);
}

@media (max-width: 1000px) {
  .laws-boxes {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .law-steps {
    padding-inline: var(--padding-inline-five-percent);
  }
}
