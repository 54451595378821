.volunteer {
  width: 100%;
  margin-bottom: var(--margin-block-big);
  border-block: 8px solid var(--red);
  overflow: hidden;
  position: relative;
  height: 550px;
  overflow-x: hidden;
}

.ban-djb {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.volunteer h2 {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0.025);
  background-color: var(--red);
  background-color: rgba(216, 32, 32, 0.75);
  color: var(--white);
  padding: 0.5rem 3rem;
  writing-mode: vertical-rl;
  transition: var(--transition-middle);
  text-align: center;
  font-size: 0%;
}

.volunteer:hover h2 {
  transform: scaleX(1);
  z-index: 7;
}

.inner-cont {
  position: absolute;
  display: grid;
  gap: var(--gap);
  place-items: center;
  place-content: center;
  top: 50%;
  text-align: center;
  color: var(--white);
  transform: scale(0) translateY(-50%);
  transition: var(--transition-middle);
  transition-delay: 0.5s;
  padding-inline: 10%;
  user-select: none;
  cursor: url("../files/cursorbig.png") 40 40, auto;
  z-index: 8;
}

.inner-cont img {
  filter: drop-shadow(var(--drop-shadow-white));
  height: 120px;
  user-select: none;
}

.volunteer:hover .inner-cont {
  transform: scale(1) translateY(-50%);
}

@media (max-width: 600px) {
  .volunteer::before {
    position: absolute;
    bottom: 0%;
    content: "Klikni na sliku";
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--white);
    padding: 0.25rem 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    height: fit-content;
    z-index: 6;
    font-size: clamp(var(--clamp-xs-text));
    opacity: 0.85;
  }
}

@media (max-width: 400px) {
  .inner-cont h1 {
    font-size: 220%;
  }
}
