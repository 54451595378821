.aims {
  width: 100%;
  margin-bottom: var(--margin-block-big);
  display: flex;
  gap: var(--gap);
  padding-inline: 180px;
  overflow-x: hidden;
  /*   cursor: url("../files/cursorbig.png") 40 40, default;
 */
}

.aim-box {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 0.5);
  flex: 1;
}

.aim-box h3 {
  max-width: 80%;
}
.aim-box h5 {
  background-color: var(--red);
  color: var(--white);
  padding: 0.25rem 2rem;
  border-radius: var(--border-radius-20);
  width: fit-content;
  box-shadow: 0 0 16px rgb(59, 59, 59);
}

.aim-box ul {
  list-style: none;
  display: grid;
  gap: 10px;
  padding-inline: 10px;
  margin-top: 20px;
}

.aim-box ul li {
  max-width: 80%;
  display: grid;
  grid-template-columns: 50px 1fr;
}

.ball {
  color: var(--red);
  margin-right: 1rem;
}

@media (max-width: 850px) {
  .aims {
    padding-inline: var(--padding-inline-five-percent);
    flex-direction: column;
  }

  .aim-box:first-of-type {
    border-bottom: solid 2px var(--red);
    padding-bottom: 2rem;
  }

  .aim-box h3 {
    max-width: 100%;
  }
}

@media (max-width: 550px) {
  .aim-box ul li {
    max-width: 100%;
  }
}
