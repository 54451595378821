.header {
  width: 100%;
  background-color: var(--red);
}

.header-one {
  /* background-image: linear-gradient(var(--red) 50%, var(--black) 50%);
  background-position: left center;
  background-size: min(750px, 75%) 15px;
  background-repeat: no-repeat; */

  width: 62%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-inline: var(--padding-inline-five-percent);
  user-select: none;
  font-size: clamp(var(--clamp-xs-text));

  background-color: var(--red);
  color: var(--white);
  cursor: default;
}

/* .flag {
  height: 30%;
  width: min(15%, 150px);
  border-radius: var(--border-radius);
} */

.text-djb {
  text-align: center;

  background-color: var(--red);
  padding-inline: 1rem;
}

.text-djb span {
  font-weight: bold;
  text-align: center;
}

.remove-decouration {
  text-decoration: none;
  color: var(--white);
}

.action-button a {
  text-decoration: none;
  background-color: var(--white);
  color: var(--red);
  padding: 3px 30px;
  transition: var(--transition-short);
  border-radius: var(--border-radius-20);
  text-transform: uppercase;
  font-weight: bold;
  border: 3px solid var(--red);
}

.action-button a:hover {
  background-color: var(--red);
  color: var(--white);
}

.atom-menu {
  position: fixed;
  right: 5%;
  top: 10px;
  background-image: url("../files/dostaje.png");
  background-size: 100%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  filter: drop-shadow(var(--drop-shadow-middle));
  transition: all 0.25s cubic-bezier(0.07, 1.39, 0.91, 1.33);
  z-index: 20;
  border: 2px solid var(--white);
  border-radius: 50%;
}

.explore-djb {
  position: fixed;
  right: 5%;
  top: 60px;
  width: max-content;
  background-color: var(--red);
  color: var(--white);
  border-radius: var(--border-radius-10);
  padding: 0.5rem 1rem;
  border: 3px solid var(--white);
  transform: scaleX(0);
  transition: var(--transition-short);
  transform-origin: right;
  text-align: center;
  font-weight: bold;
  pointer-events: none;
}

.explore-djb p {
  font-size: clamp(var(--clamp-xs-text));
}

.atom-menu:hover {
  transform: scale(1.2);
}

.atom-menu:hover .explore-djb {
  transform: scale(0.7);
}

.header-two {
  background-color: rgba(234, 233, 235, 255);
  /*   background-image: linear-gradient(45deg, white, #d82020);
 */
  height: 70vh;
  background-image: url("../files/hand19.jpeg");
  background-repeat: no-repeat;
  background-position: center 90%;
  background-size: cover;
  border-top: 3px solid var(--red);
  position: relative;
  /*   cursor: url("../files/cursorbig.png") 40 40, default;
 */
}

.header-two h1 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  text-align: center;
  color: var(--black);
  filter: drop-shadow(var(--drop-shadow));
}

.header-two h1::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: var(--border-radius-10);
  background-color: var(--red);
  transform-origin: left;
  transform: scaleX(0);
  animation: moveLine 2s ease-in-out forwards infinite 1s;
}

@keyframes moveLine {
  to {
    transform: scaleX(1);
    background-color: transparent;
  }
}

.red-track {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: var(--red);
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: var(--gap);
  pointer-events: all;
  padding: 1.5rem 5%;
  --scale: scaleX(1);
}

.red-track p {
  font-weight: normal;
  display: grid;
  place-content: center;
  font-weight: bold;
  font-size: 100%;
  text-align: center;
  width: 100%;
}

/* .black-box {
  display: hidden;
  flex: 1;
  background-color: var(--red);
  width: 1px;
  transform: scaleX(0);
  display: grid;
  place-content: center;
  position: relative;
  pointer-events: all;
} */

.red-track::before {
  content: attr(data-text);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: var(--black);
  transform-origin: right;

  transform: var(--scale);
  transition: var(--transition-middle);
  transition-duration: 0.35s;
  transition-timing-function: ease-in-out;
  padding-inline: 5%;

  display: grid;
  place-content: center;
  align-content: center;
  font-weight: bold;
  font-size: clamp(var(--clamp-head-text));
  text-transform: none;
  font-style: normal;
  letter-spacing: 1px;
}

.black-box-hand {
  transform: scale(2);
}

.menu-container {
  position: fixed;
  inset: 0;
  background-color: var(--white);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  z-index: 30;
  pointer-events: none;
  opacity: 0;
  transform-origin: right;
  transition: var(--transition-short);
  pointer-events: none;
  cursor: default;
}

.menu-container.shown {
  opacity: 1;
  pointer-events: all;
  background-color: var(--red);
  cursor: default;
}

.menu-container > * {
  z-index: 30;
  cursor: default;

  /* HEEEEEREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE */
  /*   opacity: 0;
 */
}

.mc-box {
  height: 100%;
  width: 100%;
  background-color: var(--white);
  transform: scaleX(0);

  transform-origin: right;
  transition: var(--transition-middle);
}

.mc-box:first-of-type {
  transition-delay: 0.125s;
}

.mc-box:nth-of-type(2) {
  transition-delay: 0.25s;
}

.mc-box:nth-of-type(3) {
  transition-delay: 0.375s;
}

.mc-box:nth-of-type(4) {
  transition-delay: 0.5s;
}

.mc-box:nth-of-type(5) {
  transition-delay: 0.625s;
}

.mc-box:nth-of-type(6) {
  transition-delay: 0.75s;
}

.mc-box:nth-of-type(7) {
  transition-delay: 0.875s;
}

.mc-box:nth-of-type(8) {
  transition-delay: 1s;
}

.mc-box:nth-of-type(9) {
  transition-delay: 1.125s;
}

.mc-box.shown {
  transform: scaleX(1);
  transition-delay: 0;
}

.nav-track {
  position: absolute;
  top: 0;
  left: 0;
  padding-inline: var(--padding-inline-five-percent);
  padding-block: 2rem;
  width: min(100%, 1200px);
  opacity: 0;
  transition: var(--transition-long);
  transition-delay: 1.5s;
}

.nav-track.shown {
  opacity: 1;
}

.nav-track a {
  user-select: none;
}

.nav-track-both {
  display: flex;
  justify-content: space-between;
  border-block: 1px solid rgb(199, 199, 199);
  transition-delay: 1.5s;
}

.nav-track-1,
.nav-track-2 {
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.nav-track-1 img {
  height: 50px;
  padding-block: 0.5rem;
  user-select: none;
}

.nav-track-1 p {
  font-weight: bold;
  padding-block: 0.5rem;
}

.nav-track-1 p span {
  font-weight: normal;
  font-size: clamp(var(--clamp-xs-text));
}

.nav-track-2 a {
  text-decoration: none;
  background-color: var(--red);
  color: var(--white);
  height: 100%;
  font-weight: bold;
  text-align: center;
  padding-inline: 0.25rem;
  display: grid;
  place-content: center;
  font-size: clamp(var(--clamp-xs-text));
  transition: var(--transition-short);
}

.nav-track-2 a:hover {
  background-color: var(--white);
  color: var(--red);
}

.cross {
  color: var(--red);
  cursor: pointer;
  margin-right: 1.5rem;
}

.menu-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 20px;
  margin-top: calc(var(--gap) * 1);
  border-bottom: 1px solid rgb(199, 199, 199);
  padding-bottom: calc(var(--gap) * 1);
}

.menu-links a {
  text-decoration: none;
  color: var(--grey);
  width: fit-content;
  transition: var(--transition-short);
  font-size: clamp(var(--clamp-xs-text));
}

.add-links {
  display: flex;
  gap: calc(var(--gap) * 0.5);
  margin-top: calc(var(--gap) * 0.5);
  border-bottom: 1px solid rgb(199, 199, 199);
  padding-bottom: calc(var(--gap) * 0.5);
}

.add-links a {
  text-decoration: none;
  color: var(--white);
  background-color: var(--red);
  padding: 0.75rem 2.5rem;
  border-radius: var(--border-radius-20);
  transition: var(--transition-short);
  display: grid;
  place-content: center;
  width: min(100%, 300px);
  box-shadow: 0 0 15px grey;
}

.add-links a:nth-of-type(2) {
  background-color: var(--black);
}

.add-links a:nth-of-type(3) {
  background-color: var(--lightgrey);
  color: var(--black);
}

/* .add-links a:hover {
  background-color: var(--lightred);
  color: var(--white);
} */

.finger {
  background-image: url("../files/palmcropped.png");
  height: 500px;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  left: 0;
  top: 60%;
  width: min(100%, 3000px);
  width: 100%;
  user-select: none;
  pointer-events: none;
}

.nav-track a,
.nav-track-1,
.nav-track-2,
.nav-track-1 p,
.nav-track-1 p span,
.nav-track-2 a,
.nav-track-both.shown,
.cross,
.menu-links,
.menu-links a,
.add-links,
.add-links a,
.finger {
  opacity: 0;
  transition: var(--transition-long);
}

.nav-track a.shown,
.nav-track-1.shown,
.nav-track-2.shown,
.nav-track-1 p.shown,
.nav-track-1 p span.shown,
.nav-track-2 a.shown,
.nav-track-both.shown,
.cross.shown,
.menu-links.shown,
.menu-links a.shown,
.add-links.shown,
.add-links a.shown,
.finger.shown {
  opacity: 1;
  transition-delay: 1.25s;
}

/* .menu-links a:hover {
  color: var(--red);
  transition-duration: 0.1s;
} */

.menu-links a,
.add-links a,
.nav-track-2 a {
  transition-delay: 0s;
  transition-duration: 0.25s;
}
.menu-links a:hover,
.add-links a:hover,
.nav-track-2 a:hover {
  transition-delay: 0s;
  transition-duration: 0.25s;
}

@media (max-width: 1000px) {
  .menu-links {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .header-two {
    background-position: center 100%;
  }
}

@media (max-width: 725px) {
  .header-one {
    gap: calc(var(--gap) * 0);
    width: 68%;
  }

  .header-two {
    background-position: 50% -80px;
    height: 60vh;
  }

  .header-two h1 {
    top: 11%;
  }

  .action-button a {
    padding: 8px 12px;
  }

  .nav-track-1,
  .nav-track-2 {
    gap: calc(var(--gap) * 0.5);
  }

  .add-links {
    flex-direction: column;
    gap: calc(var(--gap) * 0.5);
  }

  .finger {
    width: 100%;
    background-position: 40% 20%;
  }

  .cross {
    margin-right: 0rem;
  }
}

@media (max-width: 400px) {
  .header-two h1 {
    top: 13%;
  }
}

@media (max-width: 300px) {
  .menu-links {
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  }
}
