.home {
  width: 100%;
}

.shape {
  position: fixed;
  inset: 0;
  background-color: var(--white);
  z-index: 1000000;
  display: grid;
  place-content: center;
  place-items: center;
  user-select: none;
  gap: var(--gap);
  transition: var(--transition-middle);
  color: var(--white);
  cursor: default;
  box-shadow: inset 0 0 1rem rgb(77, 76, 76);
}

.shape img {
  width: min(80%, 650px);
  aspect-ratio: 1/1;
  filter: drop-shadow(0 0 8px silver);
}

.shape h3 {
  font-size: clamp(var(--clamp-middle-text));
  background-color: var(--red);
  padding: 0.5rem 1.25rem;
  border-radius: var(--border-radius-10);
  text-transform: uppercase;
  text-align: center;
}

.shape p {
  font-size: clamp(var(--clamp-big-text));
  font-family: var(--cursive-font);
  color: var(--black);
  text-align: center;
  opacity: 0.85;
}

.shape.remove {
  opacity: 0;
  pointer-events: none;
}

.banner {
  color: var(--red);
  margin-inline: 180px;
  padding-block: 0.5rem;
  margin-block: 100px;
  font-family: var(--cursive-font);
  font-size: clamp(var(--clamp-big-text));
  position: relative;
  width: min(700px, 100%);
  filter: drop-shadow(var(--drop-shadow-grey));
}

.make-cursive {
  font-family: var(--cursive-font);
  font-size: clamp(var(--clamp-big-text));
}

.mb-link-more {
  color: var(--white);
  background-color: var(--red);
  border: 5px solid var(--red);
  transition: var(--transition-short);
  border-radius: var(--border-radius-10);
  font-weight: bold;
  text-align: center;
  display: flex;
  width: fit-content;
  overflow: hidden;
}

.mb-link-more a {
  padding: 0.75rem 1rem;

  text-decoration: none;
  color: var(--white);
  width: fit-content;
  width: 100%;
  height: 100%;
}

.mb-link-more:hover {
  background-color: var(--white);
}

.mb-link-more:hover a {
  color: var(--red);
}

/* .banner::before,
.banner::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: min(80%, 500px);
  height: 7px;
  background-color: rgba(216, 32, 32, 1);
  transform: translateX(180px) scaleX(.75);
  border-radius: var(--border-radius-10);
  transform-origin: left;
  animation: holdLine 3s infinite linear;
}

.banner::after {
  background-color: rgba(0, 0, 0, 1);
  bottom: -17px;
  transform: translateX(180px) scaleX(.75);

  animation: holdLine 1.5s 0.75s infinite linear;
} */

.home .other h4 {
  border-block: solid 3px var(--red);
}

.home .other h3 {
  border-block: none;
}

.motivation {
  width: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  margin-top: -50px;
  padding-top: 10px;
  margin-bottom: 150px;
  padding-inline: var(--padding-inline-five-percent);
  gap: 50px;
  overflow-x: hidden;
  /*   cursor: url("../files/cursorbig.png") 40 40, default;
 */
}

.motivation img {
  width: min(100%, 500px);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: shaping 8s linear infinite alternate;
  filter: drop-shadow(0 0 8px);
  user-select: none;
  object-fit: cover;
}

.motivation h1 {
  text-align: center;
  color: var(--red);
  font-size: clamp(var(--clamp-more-text));
  font-family: var(--cursive-font);
}

.something.other,
.black-div.other {
  background-color: rgb(210, 194, 194);
  background-position: center;
  background-size: 99.9%;
  background-attachment: fixed;
  background-image: url("../files/sky.webp");
  background-blend-mode: multiply;
}

@keyframes shaping {
  12.5% {
    border-radius: 6% 94% 19% 81% / 57% 67% 33% 43%;
  }
  25% {
    border-radius: 6% 94% 19% 81% / 75% 10% 90% 25%;
  }
  50% {
    border-radius: 6% 94% 51% 49% / 43% 44% 56% 57%;
  }
  62.5% {
    border-radius: 33% 67% 22% 78% / 51% 11% 89% 49%;
  }
  75% {
    border-radius: 71% 29% 29% 71% / 69% 35% 65% 31%;
  }
  87.5% {
    border-radius: 77% 23% 70% 30% / 9% 59% 41% 91%;
  }
  100% {
    border-radius: 81% 19% 84% 16% / 16% 84% 16% 84%;
  }
}

/* .home .shadowed {
  border-bottom: 3px solid var(--red);
} */

@media (max-width: 750px) {
  .banner {
    margin-inline: var(--padding-inline-five-percent);
  }

  /*   .banner::before,
  .banner::after {
    width: 275px;
    transform: translateX(5%);
  } */
}
