.admin {
  width: 100%;
}

.admin-more {
  padding-block: var(--margin-block);
  padding-inline: 180px;
  background-color: var(--red);
  background-color: #b71f1f;
  color: var(--white);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url("../files/office.jpeg");
  background-blend-mode: overlay;
}

.admin-label {
  word-spacing: 9999px;
  margin-bottom: 20px;
}

.flag-div-red {
  width: 100%;
  background-color: var(--red);
  height: 6px;
}
.flag-div-black {
  width: 100%;
  background-color: var(--black);
  height: 6px;
}
.flag-div-white {
  width: 100%;
  height: 6px;
  background-color: rgb(224, 224, 224);
}

.add-shadow {
  width: min(80%, 500px);
  box-shadow: 0 0 50px white;
}

.expl-admin {
  width: min(100%, 700px);
  margin-bottom: var(--gap);
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-10);
  padding: 0.5rem 1.25rem;
  font-size: clamp(var(--clamp-xs-text));
}

.link-admin {
  margin-bottom: var(--gap);
  width: min(100%, 700px);
  background-color: var(--yellow);
  /*   background-color: #32a834;
 */
  color: var(--darkgrey);
  border-radius: var(--border-radius-10);
  padding: 1.5rem;
  border: 4px solid var(--white);
  font-size: clamp(var(--clamp-xs-text));
  font-weight: bold;
}

.link-admin a {
  text-decoration: none;
  color: var(--red);
  font-weight: bold;
}

.admin-form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 0.5);
  width: min(100%, 700px);
}

.admin-form > * {
  font-family: var(--standard-font);
}

.admin-form > label {
  font-size: clamp(var(--clamp-xs-text));
}

.admin-form > input {
  padding: 1rem 2rem;
  border-radius: var(--border-radius-10);
  border: 0;
  outline: 0;
  background-color: rgba(255, 255, 255, 0.35);
  font-weight: bold;
  font-size: clamp(var(--clamp-small-text));
}

.admin-form button {
  border: 0;
  outline: 0;
  width: fit-content;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: var(--border-radius-10);
  border: var(--white) 5px solid;
  background-color: var(--red);
  color: var(--white);
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
}

.auth-msg {
  border-block: 5px solid var(--yellow);
  padding-block: 20px;
}

.admin-form-article {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-block: 80px;
  padding-inline: 10%;
}

.admin-form-article > * {
  width: min(100%, 700px);
  padding: 1rem;
  border: none;
  outline: none;
  background-color: rgb(238, 234, 234);
  border-radius: 10px;
  font-family: var(--standard-font);
  font-size: clamp(var(--clamp-small-text));
}

.admin-form-article textarea {
  resize: none;
  height: 700px;
}

.admin-form-article button {
  background-color: var(--red);
  color: var(--white);
}

@media (max-width: 750px) {
  .admin-more {
    padding-inline: 5%;
  }
}
