.error {
  width: 100%;
  overflow: hidden;
}

.baltazar {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  margin-block: 80px;
}

.balt-cont {
  position: relative;
}

.link-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--red);
  color: var(--white);
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: var(--border-radius-20);
  font-weight: bold;
  filter: drop-shadow(var(--drop-shadow-white));
  border: solid 4px var(--white);
  transition: var(--transition-short);
  text-align: center;
}

.link-error:hover {
  background-color: var(--white);
  color: var(--red);
  border: solid 4px var(--red);
}

.error-h3 {
  padding-inline: 180px;
  margin-bottom: var(--margin-block-big);
  font-size: clamp(var(--clamp-small-text));
  overflow-wrap: anywhere;
  width: min(100%, 1020px);
  margin-right: 40%;
}

.error-h3 span {
  color: var(--red);
  font-style: oblique;
}

@media (max-width: 750px) {
  .error-h3 {
    padding-inline: var(--padding-inline-five-percent);
  }
}
