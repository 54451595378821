.info-box {
  width: 100%;
  padding-inline: 180px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap);
  row-gap: calc(var(--gap) * 2.5);
  margin-bottom: var(--margin-block-big);
  margin-top: 80px;
  overflow-x: hidden;
}

.info-box-details {
  display: flex;
  flex-direction: column;
  background-color: var(--red);
  border-radius: var(--border-radius-10);
  padding: 1rem;
  position: relative;
  overflow: hidden;
  height: 350px;
  filter: drop-shadow(var(--drop-shadow-lighter));
  text-align: center;

  background-position: center;
  background-size: cover;
}

.info-box-details h3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: rgba(216, 32, 32, 0.75);
  color: var(--white);
  display: grid;
  place-content: center;
  z-index: 5;
}

.info-box-details h5 {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.65);
  color: var(--white);
  padding-block: 0.5rem;
  z-index: 5;
}

.info-box-details p {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 100px;
  background-color: rgba(229, 229, 229, 0.8);
  display: grid;
  place-content: center;
  border-top: solid 2px silver;
  transition: var(--transition-middle);
  font-size: clamp(var(--clamp-xs-text));
  font-weight: bold;
  padding-inline: 0.5rem;
}

.info-box-details:first-of-type {
  background-image: url("../files/facebook.jpeg");
}

.info-box-details:nth-of-type(2) {
  background-image: url("../files/people.jpeg");
}

.info-box-details:nth-of-type(3) {
  background-image: url("../files/bckg11.jpeg");
}

.info-box-details:nth-of-type(4) {
  background-image: url("../files/silence.jpeg");
}

.info-box-details:nth-of-type(5) {
  background-image: url("../files/justice.jpeg");
}
.info-box-details:nth-of-type(6) {
  background-image: url("../files/mobile.jpeg");
}

.info-box-details:hover p {
  height: 80%;
}

@media (max-width: 750px) {
  .info-box {
    padding-inline: var(--padding-inline-five-percent);
  }
}

@media (max-width: 300px) {
  .info-box {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
