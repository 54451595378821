.nm {
  width: 100%;
  padding-inline: 180px;
  padding-block: var(--margin-block);
  font-size: clamp(var(--clamp-more-text));
  font-weight: bold;
}

@media (max-width: 750px) {
  .nm {
    padding-inline: var(--padding-inline-five-percent);
  }
}
