.last-track {
  width: 100%;
  background-color: var(--red);
  text-align: center;
  color: var(--white);
  /*   padding-block: 0.75rem;
 */
  padding-block: 1.75rem;

  font-size: clamp(var(--clamp-xs-text));
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  overflow-x: hidden;
  font-weight: bold;
  font-size: 70%;
  cursor: url("../files/cursorbig.png") 40 40, default;
}

.last-track > * {
  cursor: url("../files/cursorbig.png") 40 40, default;
}

.track-ball {
  margin-inline: 10px;
}

/* .before-track {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.before-track > * {
}

.before-track h3 {
  font-family: var(--cursive-font);
  font-size: clamp(var(--clamp-big-text));
  margin-bottom: 30px;
}

.before-track > img {
  height: 150px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: contain;
}

.logoimg {
  border-radius: 50%;
  box-shadow: 0 0 16px white;
}

@media (max-width: 750px) {
  .before-track {
    flex-direction: column;
    gap: 30px;
  }
}
 */
