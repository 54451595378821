.why-djb {
  width: 100%;
  margin-bottom: 180px;
  margin-top: 180px;
  padding-inline: 180px;
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 2.5);
  overflow-x: hidden;
}

.why-djb-box {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 0.25);
}

.why-djb-box p {
  width: min(700px, 100%);
  line-height: 1.3;
  border-block: solid 3px rgba(216, 32, 32, 0.65);
  padding-block: 1rem;
}
.why-djb-box h5 {
  background-color: var(--red);
  color: var(--white);
  padding: 0.25rem 2rem;
  border-radius: var(--border-radius-20);
  width: fit-content;
  margin-block: 20px;
  box-shadow: 0 0 16px rgb(59, 59, 59);
}

.why-djb-box h3 {
  font-size: clamp(var(--clamp-more-text));
  width: min(550px, 100%);
  font-family: var(--cursive-font);
}

/* .black-div.change-red {
  padding-block: var(--margin-block);
  padding-inline: 180px;
  background-color: #6b1414;
  color: var(--white);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url("../files/office.jpeg");
  background-blend-mode: overlay;
}

.something.change-red {
  background-color: #b71f1f;
  color: var(--white);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url("../files/office.jpeg");
  background-blend-mode: overlay;
} */

.make-bckg {
  margin-bottom: var(--gap);
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-10);
  padding: 0.5rem 1.25rem;
  font-size: clamp(var(--clamp-xs-text));
}

.something.scd-img,
.black-div.scd-img {
  background-color: #b82020;
  background-size: 99.9%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("../files/brick.webp");
  background-blend-mode: overlay;
  position: relative;
}

@media (max-width: 750px) {
  .why-djb {
    padding-inline: var(--padding-inline-five-percent);
  }

  .black-div.change-red {
    padding-inline: 5%;
  }

  .something.scd-img,
  .black-div.scd-img {
    background-size: 99.9% 99.9%;
  }
}
