.pd {
  width: 100%;
  padding-block: var(--margin-block);
  padding-inline: 180px;
}

.pd > * {
  width: min(100%, 700px);
}

.pd-label {
  margin-block: var(--gap);
  font-size: clamp(var(--clamp-pd-text));
}

.pd-label:first-of-type {
  margin-top: 0;
}

.fence-line {
  height: 10px;
  background-color: var(--red);
  border-radius: var(--border-radius-10);
}

@media (max-width: 750px) {
  .pd {
    padding-inline: 5%;
  }
}
