.dm {
  width: 100%;
  margin-block: var(--margin-block);
  padding-inline: 180px;
  line-height: 1.2;
}

.dm > * {
  width: min(100%, 700px);
}

.accesssecond {
  color: var(--black);
}

.mark-pgh {
  margin-bottom: 80px;
}

.mark-pgh p span {
  color: var(--red);
  font-weight: bold;
}

.mark-pgh h4 {
  background-color: var(--yellow);
  padding: 1rem;
  border-radius: var(--border-radius-10);
  box-shadow: 0 0 30px silver;
}

.dm-href {
  padding: 0.5rem 1.25rem;
  border: 5px solid var(--red);
  background-color: var(--red);
  border-radius: var(--border-radius-20);
  font-weight: bold;
  transition: var(--transition-short);
  width: fit-content;
  box-shadow: 0 0 16px rgb(90, 90, 90);
}

.dm-href a {
  text-decoration: none;
  color: var(--white);
}

.dm-href:hover {
  background-color: var(--white);
  color: var(--red);
}

.dm-href:hover a {
  color: var(--red);
}

.dm img {
  width: min(100%, 700px);
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: var(--border-radius-10);
  filter: drop-shadow(var(--drop-shadow-lighter));
  transition: var(--transition-short);
}

.dm img:hover {
  transform: scale(0.98);
}

/* .black-pgh  {
    background-color: var(--black);
} */

.grey-line {
  width: min(100%, 700px);
  height: 2px;
  background-color: rgba(160, 160, 160, 0.5);
  border-radius: var(--border-radius-10);
}

.donate-warn {
  display: grid;
  grid-template-columns: 80px 1fr;
  place-items: center;
}

.donate-warn h5 {
  text-align: center;
  border-left: dotted 2px silver;
  padding-left: 12px;
}

.donate-warn h5 span {
  color: var(--red);
}

.dw-icon {
  color: var(--red);
  transform: scale(2.5);
}

@media (max-width: 750px) {
  .dm {
    padding-inline: 5%;
  }

  .donate-warn {
    grid-template-columns: 1fr;
  }

  .donate-warn h5 {
    border-left: 0;
    border-top: dotted 2px silver;
    margin-top: 60px;
    padding-top: 20px;
  }

  .dw-icon {
    transform: scale(7);
  }
}
