/* .about-more {
  width: 100%;
  padding-block: var(--margin-block);
  padding-inline: 180px;
  background-color: var(--red);
  background-color: #b71f1f;
  color: var(--white);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url("../files/office.jpeg");
  background-blend-mode: overlay;
}
 */

.admin-label.no-breaking {
  word-spacing: initial;
  width: min(100%, 550px);
}

.whiten-logo {
  filter: drop-shadow(0 0 32px white);
  user-select: none;
}

.whiten-logo-flag {
  width: min(100%, 700px);
  border-radius: var(--border-radius-10);
  user-select: none;
}
