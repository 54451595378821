.last-posts {
  width: 100%;
  margin-bottom: 80px;
  padding-inline: 180px;
  display: grid;
  gap: 60px;
  overflow-x: hidden;
  margin-top: 100px;
}

.last-posts a {
  text-decoration: none;
  color: var(--red);
  background-color: var(--white);
  padding: 3px 30px;
  transition: var(--transition-short);
  border-radius: var(--border-radius-20);
  text-transform: uppercase;
  font-weight: bold;
  border: 5px solid var(--red);
  width: fit-content;
  text-align: center;
}

.last-posts a:hover {
  background-color: var(--red);
  color: var(--white);
}

.lp-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap);
  row-gap: 90px;
}

.lp-box {
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-10);
  box-shadow: 0 0 16px;
  position: relative;
  height: 350px;
  cursor: pointer;
}

.lp-box a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
  user-select: none;
  transition: var(--transition-short);
}

.lp-box a p {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  display: grid;
  place-content: center;
  padding: 0.5rem 1.5rem;
  background-color: rgba(216, 32, 32, 0.75);
  color: var(--white);
  overflow: hidden;
  text-transform: none;
  font-size: 90%;
  font-weight: normal;
  text-align: left;
}

.lp-box a h5 {
  background-color: rgba(0, 0, 0, 0.65);
  color: var(--white);
  padding: 0.5rem 1.5rem;
  position: absolute;
  width: 100%;
  bottom: 80px;
  overflow: hidden;
  text-transform: none;
  text-align: left;
}

.lp-box:hover a img {
  filter: grayscale(1);
}

/* .down-a {
  padding-block: 2rem;
} */

@media (max-width: 750px) {
  .last-posts {
    padding-inline: var(--padding-inline-five-percent);
  }
}

@media (max-width: 300px) {
  .lp-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
