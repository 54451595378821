.basic-div {
  width: 100%;
  overflow: hidden;
}

.black-div {
  width: 100%;
  background-color: var(--black);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 40px;
  text-align: center;

  padding: 5rem 10%;
  user-select: none;

  margin-bottom: 120px;
}

.black-div h3 {
  font-size: clamp(var(--clamp-site-text));
}

.black-div img {
  width: min(300px, 80%);
  filter: drop-shadow(var(--drop-shadow-middle));
  border-radius: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}
.redcolour {
  background-color: #d82020;
  color: white;
}

.blackcolour {
  background-color: black;
  color: white;
}

.basic-div table {
  width: min(100%, 1000px);
  background-color: var(--white);
  font-size: clamp(var(--clamp-xs-text));
}

.nscz-expl {
  padding-inline: 180px;
}

.move-table,
.toggler,
.smt-h3,
.something p,
.tbl-stars,
.mb-link.centre-txt {
  padding-inline: 180px;
}

.mb-link.centre-txt {
  width: min(100%, 1060px);
  color: var(--white);
}

.going-up {
  margin-top: -80px;
}

.move-table {
  height: 450px;
  overflow: hidden;
  transition: var(--transition-long);
  overflow-y: scroll;
  background-color: var(--black);
  padding-block: 80px;
  position: relative;
}

.something {
  background-color: var(--black);
  padding-block: 80px;
  filter: drop-shadow(var(--drop-shadow-lighter));
}

.something p {
  color: var(--white);
  padding-top: 1rem;
  padding-inline: 180px;
  width: min(100%, 1060px);
  border-top: 5px solid var(--red);
}

.move-table::-webkit-scrollbar {
  width: 10px;
}

.move-table::-webkit-scrollbar-track {
  background-color: #d82020;
}
.move-table::-webkit-scrollbar-thumb {
  background-color: var(--white);
  border-radius: 40px;
  border: 2px solid #d82020;
}

.move-table.show-tbl {
  height: 100%;
  overflow: hidden;
}

.toggler {
  user-select: none;
  cursor: pointer;
}

.nscz-expl > * {
  width: min(700px, 100%);
}

.smt-h3 {
  width: min(1060px, 100%);
  color: var(--white);
}

.tbl-stars {
  color: var(--yellow);
  font-style: oblique;
  width: min(1060px, 100%);
  font-size: clamp(var(--clamp-xs-text));
}

.make-black {
  color: var(--darkgrey);
}

.nscz-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  row-gap: 60px;

  place-items: start;
  cursor: url("../files/cursorbig.png") 40 40, default;
}

.nscz-images-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-inline: 5%;
}

.nscz-images-box img {
  user-select: none;
  width: 90%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  place-self: center;
  box-shadow: 0 0 16px;
}

.nscz-images-box h4 {
  place-self: center;
  text-align: center;
  font-size: clamp(var(--clamp-xs-text));
  border-block: rgba(216, 32, 32, 0.25) solid 3px;
  padding-block: 10px;
}

.hdz-virus {
  border-radius: var(--border-radius-10);
  padding-left: 180px;
  width: calc(80% - 360px);
  user-select: none;
  filter: drop-shadow(var(--drop-shadow-white));
}

.fb-link {
  background-color: var(--red);
  padding: 0.5rem 1.25rem;
  border-radius: 10px;
  font-weight: bold;
  border: 5px solid var(--red);
  transition: var(--transition-short);
  text-align: center;
}

.fb-link a {
  text-decoration: none;
  color: var(--white);
  text-align: center;
}

.fb-link:hover {
  color: var(--red);
  background-color: var(--white);
}

.fb-link:hover a {
  color: var(--red);
}

@media (max-width: 1150px) {
  .black-div {
    /*     margin-top: -150px;
 */
    flex-direction: column;
  }

  .hdz-virus {
    width: calc(80% - 0px);
  }
}

@media (max-width: 750px) {
  .nscz-expl,
  .move-table,
  .toggler,
  .smt-h3,
  .something p,
  .tbl-stars,
  .mb-link.centre-txt {
    padding-inline: 5%;
  }

  .hdz-virus {
    padding-left: 5%;
    width: 90%;
  }
}

@media (max-width: 600px) {
  .black-div {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 380px) {
  .black-div {
    font-size: 18pt;
    padding: 20px 30px;
  }
}
