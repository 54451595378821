.newsletter {
  width: 100%;
  margin-bottom: 80px;
  background-color: rgb(62, 62, 62);

  background-image: url("../files/zagreb5.webp");
  background-size: cover;
  background-position: 15% bottom;
  background-attachment: fixed;
  background-blend-mode: overlay;
  color: var(--white);
  padding-inline: 180px;
  padding-block: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  overflow: hidden;
}

.newsletter h3 {
  margin-bottom: 80px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(216, 32, 32, 0.75);
  padding-inline: 180px;
  padding-block: 0.5rem;
  user-select: none;
}

.newsletter h4 {
  width: min(100%, 550px);
  margin-top: 80px;
}

.newsletter p {
  width: min(100%, 700px);
  color: var(--white);
  font-style: oblique;
  font-size: clamp(var(--clamp-xs-text));
  border-radius: var(--border-radius-10);
  background-color: var(--red);
  background-color: rgba(216, 32, 32, 0.85);
  padding: 1.5rem 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  opacity: 0.8;
  line-height: 1.3;
}

.newsletter a {
  color: var(--white);
  width: fit-content;
  text-decoration: none;
  position: relative;
  filter: drop-shadow(var(--drop-shadow-white));
}
.newsletter a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 3px;
  width: 100%;
  transform: scaleX(1);
  transition: var(--transition-short);
  background-color: var(--red);
  border-radius: var(--border-radius-10);
  transform-origin: right;
}

.newsletter a:hover::before {
  transform: scaleX(0);
}

.newsletter button {
  text-decoration: none;
  background-color: var(--red);
  color: var(--white);
  width: fit-content;
  font-family: var(--standard-font);
  padding: 0.5rem 1.5rem;
  border: 0;
  outline: 0;
  border-radius: var(--border-radius-20);
  border: 4px solid var(--white);
  transition: var(--transition-short);
  font-size: clamp(var(--clamp-small-text));
  margin-bottom: 30px;
  margin-top: 40px;
  font-weight: bold;
}

.newsletter a {
  margin-block: 30px;
}

.newsletter button:hover {
  background-color: var(--darkgrey);
  color: var(--white);
  cursor: pointer;
}

.newsletter input {
  width: min(100%, 400px);
  padding: 0.75rem 2rem;
  border-radius: var(--border-radius-20);
  outline: 0;
  border: 0;
  font-family: var(--standard-font);
  font-size: clamp(var(--clamp-small-text));
  background-color: rgba(0, 0, 0, 0.45);
  background-color: rgba(255, 255, 255, 0.45);
  color: var(--black);
  font-weight: bold;
  filter: drop-shadow(var(--drop-shadow-grey));
}

.newsletter input::placeholder {
  color: var(--white);
}

@media (max-width: 750px) {
  .newsletter,
  .newsletter h3 {
    padding-inline: var(--padding-inline-five-percent);
  }
}
