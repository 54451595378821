.vd {
  width: 100%;
  padding-block: var(--margin-block);
  padding-inline: 180px;
}

.vd > * {
  width: min(100%, 700px);
}

.act-vol {
  text-decoration: none;
  color: var(--white);
  position: relative;
  font-weight: bold;
  text-shadow: 0 0 100px white;
}

.act-vol::before,
.act-vol::after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 0;
  height: 5px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--red) 33%,
    var(--black) 33%,
    var(--black) 67%,
    white 67%
  );
  transition: var(--transition-middle);
  transform-origin: right;
  border-radius: var(--border-radius-10);
}

.act-vol::after {
  top: -16px;
  background-image: linear-gradient(
    to left,
    var(--red) 33%,
    var(--black) 33%,
    var(--black) 67%,
    white 67%
  );
  transform-origin: left;
}

.act-vol:hover::before {
  transform: scaleX(0);
}

.act-vol:hover::after {
  transform: scaleX(0);
}

.for-links a {
  text-decoration: none;
  color: var(--red);
  font-weight: bold;
}

.volont-img {
  position: relative;
  border-radius: var(--border-radius-10);
  height: 400px;
  filter: drop-shadow(var(--drop-shadow-lighter));

  background-color: rgb(252, 104, 104);
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

/* .volont-img img {
  width: 100%;
  height: 100%;  object-fit: cover;
  object-position: center;
} */

.volont-img:first-of-type {
  background-image: url("../files/volonter.jpeg");
  background-color: rgb(255, 113, 113);
}

.volont-img:nth-of-type(2) {
  background-image: url("../files/volonter2.jpeg");
  background-color: rgb(226, 61, 61);
}

.volont-img:nth-of-type(3) {
  background-position: left;
  background-image: url("../files/protest811.jpeg");
}

.volont-img:nth-of-type(5) {
  background-image: url("../files/cave.jpeg");
  background-color: rgb(252, 130, 130);
}

.volont-img:nth-of-type(4) {
  background-image: url("../files/girlpexels.jpeg");
  background-color: rgb(252, 130, 130);
}

.volont-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vol-ul li {
  margin-bottom: 10px;
}

.vol-ul li::marker {
  content: "\2714     ";
  color: var(--white);
}

.vday {
  border-radius: var(--border-radius-10);
  overflow: hidden;
  filter: drop-shadow(var(--drop-shadow-lighter));
}

.vday h3 {
  padding: 1.5rem;
  background-color: var(--red);
  color: var(--white);
}

.vday h4 {
  padding: 1.5rem;
  background-color: var(--darkgrey);
  color: var(--white);
}

.vday p {
  padding: 1.5rem;
  background-color: rgb(218, 218, 218);
}

.vd p,
.vd ul li {
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.25);
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius-10);
}

.decker {
  /* 
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: shaping 5s ease-in-out infinite alternate; 
  */
  filter: drop-shadow(0 0 8px silver);
  user-select: none;
  width: min(475px, 100%);
  height: auto;
  object-fit: cover;
}

.red-warn,
.yellow-warn,
.green-warn {
  color: var(--white);
  background-color: var(--red);
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius-10);
  border: 3px solid var(--white);
}

.yellow-warn {
  color: var(--black);
  background-color: var(--yellow);
}

.green-warn {
  color: var(--white);
  background-color: #32a834;
}

@media (max-width: 750px) {
  .vd {
    padding-inline: 5%;
  }
}
