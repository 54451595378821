.memb-details {
  width: 100%;
  margin-block: var(--margin-block);
  /*  background-color: var(--red);
  margin-top: 0;
  padding-top: var(--margin-block); */
}

.toleft {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toleft:first-of-type {
  margin-top: -60px;
}

.line-down {
  border-bottom: 3px solid rgb(224, 224, 224);
  padding-bottom: 40px;
}

.toleft-col {
  display: flex;
  gap: 20px;
  align-items: center;
}

.label-h5 {
  color: rgb(167, 167, 167);
  color: var(--grey);
  font-style: oblique;
  margin-block: 10px;
  background-color: var(--yellow);
  padding: 0.25rem 0.75rem;
  border-radius: var(--border-radius-10);
}

.secondone {
  background-color: var(--red);
  color: var(--white);
  padding-inline: 180px;
  padding-block: var(--margin-block);
}

.secondone > * {
  width: min(100%, 700px);
}

.accesssecond {
  font-size: clamp(var(--clamp-big-text));
  padding: 1rem 0rem;
  width: fit-content;
  color: var(--white);
  margin-bottom: var(--margin-block);
  filter: drop-shadow(5px 5px 30px rgb(52, 52, 52));
  font-family: var(--cursive-font);
  font-weight: bold;
  letter-spacing: 2px;
  word-spacing: 4px;
  user-select: none;
  width: min(100%, 700px);

  border-block: silver 3px solid;
}

.decordivagain {
  line-height: 1.3;
}

.memb-img {
  width: 50%;
  border-radius: var(--border-radius-10);
  filter: drop-shadow(var(--drop-shadow-lighter));
  transition: var(--transition-short);
}

.memb-img:hover {
  transform: scale(0.98);
}

.iban-h4 {
  background-color: var(--yellow);
  padding: 0.75rem 1.75rem;
  width: min(100%, 700px);
  border-radius: var(--border-radius-10);
  color: var(--darkgrey);
  font-weight: bold;
}

.memb-conditions {
  background-color: var(--black);
  filter: drop-shadow(var(--drop-shadow-lighter));
  background-image: url("../files/office.jpeg");
  background-position: center;
  background-size: cover;
  background-color: rgb(78, 78, 78);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.accesssecond.more-shadow {
  filter: drop-shadow(5px 5px 30px rgb(255, 255, 255));
  color: var(--white);
}

@media (max-width: 750px) {
  .secondone {
    padding-inline: 5%;
  }

  .memb-img {
    width: 100%;
  }
}
